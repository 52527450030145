// LoginModal.js
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input } from 'reactstrap';
import Cookies from 'js-cookie';
import '../../../assets/ehs/app-style.css';


class LoginModal extends Component {
  state = {
    email: '',
    password: '',
    modal: true,
    loginFailed: false
  };

  updateParentBackground = (add) => {
    //console.log("updateParentBackground", add);
    const parent = document.querySelector('.app-container'); 
    if (add) {
      parent.style.backgroundImage = "url('test/stock-photo-black-stethoscope-healthcare-1030781125.png')"; // Set the background image
      parent.style.backgroundSize = 'cover';
      parent.style.backgroundPosition = 'center';
    } else {
      parent.style.backgroundImage = 'none'; 
    }
  };
  
  componentDidMount() {
    if (this.state.modal) {
      this.updateParentBackground(true);
    }
  }
  

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }), () => {
      this.updateParentBackground(this.state.modal);
    });
    if (this.props.onClose) {
      this.props.onClose();
    }
  };
  
  

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleLogin = () => {
    const { email, password } = this.state;
    // Hardcoded credentials for demonstration purposes
    const hardcodedCredentials = {
      email: 'bill@ehs.net',
      password: '@test123'
    };

    if (email === hardcodedCredentials.email && password === hardcodedCredentials.password) {
      Cookies.set('loggedIn', 'true', { expires: 1 }); // Set a cookie for 1 day
      this.setState({ loginFailed: false });
      this.updateParentBackground(false);
      this.toggle();
      this.props.onLoginSuccess();
    } else {
      this.setState({ loginFailed: true });
    }
  };

  render() {
    const { email, password, loginFailed } = this.state;

    return (
      <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
        <ModalHeader toggle={this.toggle}>Login</ModalHeader>
        <ModalBody>
          {loginFailed && <div className="alert alert-danger">Invalid email or password</div>}
          <FormGroup>
            <Label for="exampleEmail">Email</Label>
            <Input
              type="email"
              name="email"
              id="exampleEmail"
              value={email}
              placeholder="Email here..."
              onChange={this.handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="examplePassword">Password</Label>
            <Input
              type="password"
              name="password"
              id="examplePassword"
              value={password}
              placeholder="Password here..."
              onChange={this.handleInputChange}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.handleLogin}>Login</Button>{' '}
          <Button color="secondary" onClick={this.toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default LoginModal;
